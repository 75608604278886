import { useRouter } from 'next/router';
import UserContext from '../src/UserContext';
import { useContext, useState } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(4),
    },
  },
}));

export default function Login() {
  const router = new useRouter();
  let { keycloakstate, setKeycloakstate } = useContext(UserContext);
  const classes = useStyles();

  if (keycloakstate.authenticated === true) {
    router.push('/dashboard');
  }

  return (
    <Container maxWidth='xl'>
      <Box display='flex' justifyContent='center' m={1} p={1}>
        <Typography variant='h6' noWrap>
          Παρακαλώ περιμένετε
        </Typography>
        <div className={classes.root}>
          <CircularProgress />
        </div>
      </Box>
    </Container>
  );
}
